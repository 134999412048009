const getters = {
  getWeb3: state => {
    return state.web3;
  },
  getUserAccount: state => {
    return state.userAccount;
  },
  getInstance: state => {
    return state.instance;
  },
  getRate: state => {
    return state.rate;
  },
  getReferral: state => {
    return state.referral;
  },
  getContractAddress: state => {
    return state.contractAddress;
  },
  getContractABI: state => {
    return state.contractABI;
  },

};

export default getters;
