<template>
  <section class="main-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 ps-lg-4 pe-lg-5">
          <div class="card border-0 mb-3 me-lg-n3">
            <div class="card-header bg-white border-0 pb-0">
              <p class="card-title mb-0">Stake BNB</p>
            </div>
            <div class="card-body p-0">
              <table class="table">
                <thead class="border-bottom">
                  <tr>
                    <td scope="col" class="text-capitalize">
                      <small>days</small>
                    </td>
                    <td scope="col" class="text-capitalize">
                      <small>%daily</small>
                    </td>
                    <td scope="col" class="text-capitalize">
                      <small>total</small>
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody class="position-relative">
                  <tr class="cursor-pointer" @click="row_one = !row_one">
                    <td scope="row" class=""><span> Forever </span></td>
                    <td scope="row" class=""><span> 2% </span></td>
                    <td scope="row" class=""><span> ∞ </span></td>
                    <td scope="row" class=""></td>
                    <td scope="row" class="">
                      <button class="border-0 bg-transparent">
                        <span class="angle mb-1"></span>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="row_one">
                    <td
                      colspan="5"
                      class="p-0 border-0 position-relative end-0"
                    >
                      <div class="sub-item overflow-hidden">
                        <div class="bg-secondary rounded-bottom p-3">
                          <div class="p-1 pe-3">
                            <div class="d-flex mb-3">
                              <span class="text-capitalize me-3"
                                >Total Earn: ∞</span
                              >
                            </div>
                            <form class="w-lg-75 ms-lg-auto ps-md-4">
                              <fieldset class="d-flex w-lg-75 ms-auto">
                                <input
                                  type="number"
                                  min="0.05"
                                  step="0.01"
                                  class="form-control me-n11"
                                  placeholder="Enter BNB amount"
                                  @input="(e) => calculateTotalEarning(e, 0)"
                                  v-model="$v.plan_one_amount.$model"
                                />
                                <button
                                  :disabled="
                                    !getUserAccount || $v.plan_one_amount.$error
                                  "
                                  @click.prevent="investBNB(0)"
                                  class="btn btn-primary py-2 px-4"
                                >
                                  <span class="py-1 d-block px-3"> Stake </span>
                                </button>
                              </fieldset>
                              <template v-if="$v.plan_one_amount.$error">
                                <span
                                  v-if="!$v.plan_one_amount.minValue"
                                  class="error-message"
                                  >Minimim value is 0.05</span
                                >
                              </template>
                            </form>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody class="position-relative">
                  <tr class="cursor-pointer" @click="row_two = !row_two">
                    <td scope="row" class=""><span> 40 </span></td>
                    <td scope="row" class=""><span> 4% </span></td>
                    <td scope="row" class=""><span> 160% </span></td>
                    <td scope="row" class=""></td>
                    <td scope="row" class="">
                      <button class="border-0 bg-transparent">
                        <span class="angle mb-1"></span>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="row_two">
                    <td
                      colspan="5"
                      class="p-0 border-0 position-relative end-0"
                    >
                      <div class="sub-item overflow-hidden">
                        <div class="bg-secondary rounded-bottom p-3">
                          <div class="p-1 pe-3">
                            <div class="d-flex mb-3">
                              <span class="text-capitalize me-3"
                                >Total Earn: {{ total_earning_two }} BNB</span
                              >
                            </div>
                            <form class="w-lg-75 ms-lg-auto ps-md-4">
                              <fieldset class="d-flex w-lg-75 ms-auto">
                                <input
                                  type="number"
                                  min="0.05"
                                  step="0.01"
                                  class="form-control me-n11"
                                  placeholder="Enter BNB amount"
                                  @input="(e) => calculateTotalEarning(e, 1)"
                                  v-model="$v.plan_two_amount.$model"
                                />
                                <button
                                  :disabled="
                                    !getUserAccount || $v.plan_two_amount.$error
                                  "
                                  @click.prevent="investBNB(1)"
                                  class="btn btn-primary py-2 px-4"
                                >
                                  <span class="py-1 d-block px-3"> Stake </span>
                                </button>
                              </fieldset>
                              <template v-if="$v.plan_two_amount.$error">
                                <span
                                  v-if="!$v.plan_two_amount.minValue"
                                  class="error-message"
                                  >Minimim value is 0.05</span
                                >
                              </template>
                            </form>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody class="position-relative">
                  <tr class="cursor-pointer" @click="row_three = !row_three">
                    <td scope="row" class=""><span> 60 </span></td>
                    <td scope="row" class=""><span> 3.5% </span></td>
                    <td scope="row" class=""><span> 210% </span></td>
                    <td scope="row" class=""></td>
                    <td scope="row" class="">
                      <button class="border-0 bg-transparent">
                        <span class="angle mb-1"></span>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="row_three">
                    <td
                      colspan="5"
                      class="p-0 border-0 position-relative end-0"
                    >
                      <div class="sub-item overflow-hidden">
                        <div class="bg-secondary rounded-bottom p-3">
                          <div class="p-1 pe-3">
                            <div class="d-flex mb-3">
                              <span class="text-capitalize me-3"
                                >Total Earn: {{ total_earning_three }} BNB</span
                              >
                            </div>
                            <form class="w-lg-75 ms-lg-auto ps-md-4">
                              <fieldset class="d-flex w-lg-75 ms-auto">
                                <input
                                  type="number"
                                  min="0.05"
                                  step="0.01"
                                  class="form-control me-n11"
                                  placeholder="Enter BNB amount"
                                  @input="(e) => calculateTotalEarning(e, 2)"
                                  v-model="$v.plan_three_amount.$model"
                                />
                                <button
                                  :disabled="
                                    !getUserAccount ||
                                    $v.plan_three_amount.$error
                                  "
                                  @click.prevent="investBNB(2)"
                                  class="btn btn-primary py-2 px-4"
                                >
                                  <span class="py-1 d-block px-3"> Stake </span>
                                </button>
                              </fieldset>
                              <template v-if="$v.plan_three_amount.$error">
                                <span
                                  v-if="!$v.plan_three_amount.minValue"
                                  class="error-message"
                                  >Minimim value is 0.05</span
                                >
                              </template>
                            </form>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody class="position-relative">
                  <tr class="cursor-pointer" @click="row_four = !row_four">
                    <td scope="row" class=""><span> 90 </span></td>
                    <td scope="row" class=""><span> 3% </span></td>
                    <td scope="row" class=""><span> 270% </span></td>
                    <td scope="row" class=""></td>
                    <td scope="row" class="">
                      <button class="border-0 bg-transparent">
                        <span class="angle mb-1"></span>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="row_four">
                    <td
                      colspan="5"
                      class="p-0 border-0 position-relative end-0"
                    >
                      <div class="sub-item overflow-hidden">
                        <div class="bg-secondary rounded-bottom p-3">
                          <div class="p-1 pe-3">
                            <div class="d-flex mb-3">
                              <span class="text-capitalize me-3"
                                >Total Earn: {{ total_earning_four }} BNB</span
                              >
                            </div>
                            <form class="w-lg-75 ms-lg-auto ps-md-4">
                              <fieldset class="d-flex w-lg-75 ms-auto">
                                <input
                                  type="number"
                                  min="0.05"
                                  step="0.01"
                                  class="form-control me-n11"
                                  placeholder="Enter BNB amount"
                                  @input="(e) => calculateTotalEarning(e, 3)"
                                  v-model="$v.plan_four_amount.$model"
                                />
                                <button
                                  :disabled="
                                    !getUserAccount ||
                                    $v.plan_four_amount.$error
                                  "
                                  @click.prevent="investBNB(3)"
                                  class="btn btn-primary py-2 px-4"
                                >
                                  <span class="py-1 d-block px-3"> Stake </span>
                                </button>
                              </fieldset>
                              <template v-if="$v.plan_four_amount.$error">
                                <span
                                  v-if="!$v.plan_four_amount.minValue"
                                  class="error-message"
                                  >Minimim value is 0.05</span
                                >
                              </template>
                            </form>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card border-0 mb-3 me-lg-n3 d-lg-block d-none">
            <div class="card-header bg-white border-0 pb-0">
              <p class="card-title mb-0">Read before use</p>
            </div>
            <div class="card-body">
              <small class="fw-light mt-n3 d-block lh-xs">
                The principal deposit cannot be withdrawn, the only return users
                can get are daily dividends and referral rewards. Payments is
                possible only if contract balance have enough BNB. Please
                analyze the transaction history and balance of the smart
                contract before investing. High risk - high profit, DYOR 
              </small>
              <p>There is no auto-compounding option; however, users can withdraw and re-invest their daily earnings to compound their gains.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 px-lg-0">
          <div class="card border-0 mb-4">
            <div class="card-header bg-white border-0 pb-0">
              <p class="card-title mb-0">Your Farm</p>
            </div>
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <div class="w-100">
                  <p class="mb-0"><small>BNB to Harvest:</small></p>
                  <p class="fs-5 fw-bold mb-0">{{ userAvailable }} BNB</p>
                  <p class="mb-0">
                    <small
                      >$
                      {{
                        (Number(getRate) * Number(userAvailable)).toFixed(8)
                      }}</small
                    >
                  </p>
                </div>
                <button
                  :disabled="!getUserAccount"
                  @click="harvest"
                  class="btn btn-primary ms-2 w-50"
                >
                  Harvest
                </button>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div class="w-100">
                  <p class="mb-0"><small>BNB in Wallet:</small></p>
                  <p class="fs-5 fw-bold mb-0">{{ balance }} BNB</p>
                  <p class="mb-0">
                    <small
                      >$
                      {{
                        (Number(getRate) * Number(balance)).toFixed(8)
                      }}</small
                    >
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div class="w-100">
                  <p class="mb-0"><small>Total Deposit:</small></p>
                  <p class="fs-5 fw-bold mb-0">{{ totalDeposit }} BNB</p>
                  <p class="mb-0">
                    <small
                      >$ {{ (Number(getRate) * Number(0)).toFixed(8) }}</small
                    >
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div class="w-100">
                  <p class="mb-0"><small>Total Withdrawn:</small></p>
                  <p class="fs-5 fw-bold mb-0">{{ totalWithdrawn }} BNB</p>
                  <p class="mb-0">
                    <small
                      >$ {{ (Number(getRate) * Number(0)).toFixed(8) }}</small
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-0 mb-3">
            <div class="card-header bg-white border-0 pb-0">
              <p class="card-title mb-0">Affiliate Program</p>
            </div>
            <div class="card-body">
              <p class="mb-0">1 LVL <small>(your invited user)</small> - 7%</p>
              <p class="mb-0">
                2 LVL <small>(user invited by your 1 lvl)</small> - 3%
              </p>
              <p class="mb-0">3 LVL - 1.5%</p>
              <p class="mb-0">4 LVL - 1%</p>
              <p>5 LVL - 0.5%</p>
              <div class="bg-secondary rounded p-3 mb-3">
                <p>Your personal link:</p>
                <div>
                  <input
                    type="text"
                    class="tron-link position-absolute"
                    style="opacity: 0"
                  />
                  <div class="d-flex w-100">
                    <div class="mb-0 w-100 p-2 ps-3 bg-white rounded me-n11">
                      <small
                        id="copy-link"
                        class="py-1 mt-1 copy-link d-block text-truncate"
                      >
                      https://bnbmill.com/?r={{ getUserAccount }}
                      </small>
                    </div>
                    <div class="tooltip-container">
                      <button class="btn btn-primary py-2" @click="copy">
                        <span class="py-1 d-block"> Copy </span>
                      </button>
                      <span class="tooltip tron-tooltip">{{
                        copied || "copy"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <p>Invited users: {{ totalReferrals }}</p>
              <p>Total Earnings: {{ totalBonus }} BNB</p>
            </div>
          </div>
          <div class="card border-0 mb-3 me-lg-n3 d-lg-none">
            <div class="card-header bg-white border-0 pb-0">
              <p class="card-title mb-0">Read before use</p>
            </div>
            <div class="card-body">
              <small class="fw-light mt-n3 d-block lh-xs">
                The principal deposit cannot be withdrawn, the only return users
                can get are daily dividends and referral rewards. Payments is
                possible only if contract balance have enough BNB. Please
                analyze the transaction history and balance of the smart
                contract before investing. High risk - high profit, DYOR
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { minValue } from "vuelidate/lib/validators";
export default {
  name: "Dashboard",
  data() {
    return {
      timer: null,
      copied: false,
      row_one: false,
      row_two: false,
      row_three: false,
      row_four: false,
      plan_one_amount: null,
      plan_two_amount: null,
      plan_three_amount: null,
      plan_four_amount: null,
      total_earning_two: 0,
      total_earning_three: 0,
      total_earning_four: 0,
      balance: "0.00000000",
      userAvailable: "0.00000000",
      totalDeposit: "0.00000000",
      totalWithdrawn: "0.00000000",
      totalReferrals: "0",
      totalBonus: "0.00000000",
    };
  },
  validations: {
    plan_one_amount: {
      minValue: minValue(0.05),
    },
    plan_two_amount: {
      minValue: minValue(0.05),
    },
    plan_three_amount: {
      minValue: minValue(0.05),
    },
    plan_four_amount: {
      minValue: minValue(0.05),
    },
  },
  mounted() {
    this.readValue();
    this.timer = setInterval(() => {
      this.readValue();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    copy() {
      var copyText = document.getElementById("copy-link");
      navigator.clipboard.writeText(copyText.innerHTML);
      this.copied = "Copied";
    },
    readValue() {
      if (this.getWeb3) {
        let methods = this.getInstance.methods;
        Promise.all([
          this.getWeb3.eth.getBalance(this.getUserAccount),
          methods.getUserInfo(this.getUserAccount).call(),
          methods.getUserAvailable(this.getUserAccount).call(),
          methods.getUserTotalReferrals(this.getUserAccount).call(),
          methods.getUserTotalWithdrawn(this.getUserAccount).call(),
          methods.getUserReferralTotalBonus(this.getUserAccount).call(),
        ]).then(
          ([
            balance,
            userInfo,
            userAvailable,
            totalReferrals,
            totalWithdrawn,
            totalBonus,
          ]) => {
            this.balance = this.humanized(balance, 8);
            this.totalDeposit = this.humanized(userInfo.totalDeposit, 8);
            this.userAvailable = this.humanized(userAvailable, 8);
            this.totalReferrals = totalReferrals;
            this.totalWithdrawn = this.humanized(totalWithdrawn, 8);
            this.totalBonus = this.humanized(totalBonus, 8);
          }
        );
      }
    },
    humanized(number, fix) {
      return Number(
        this.getWeb3.utils.fromWei(number.toString(), "ether")
      ).toFixed(fix);
    },
    calculateTotalEarning(e, plan) {
      switch (Number(plan)) {
        case 0:
          break;
        case 1:
          this.total_earning_two = Number(e.target.value * 160) / 100;
          break;
        case 2:
          this.total_earning_three = Number(e.target.value * 210) / 100;
          break;
        case 3:
          this.total_earning_four = Number(e.target.value * 270) / 100;
          break;
      }
    },
    investBNB(plan) {
      let amount = 0;
      switch (Number(plan)) {
        case 0:
          amount = this.plan_one_amount;
          break;
        case 1:
          amount = this.plan_two_amount;
          break;
        case 2:
          amount = this.plan_three_amount;
          break;
        case 3:
          amount = this.plan_four_amount;
          break;
      }

      this.getInstance.methods
        .invest(this.getReferral, plan)
        .send({
          from: this.getUserAccount,
          value: this.getWeb3.utils.toWei(amount.toString(), "ether"),
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction hash", hash);
          this.$toasted.show("Transaction submitted to the network");
        })
        .on("receipt", (receipt) => {
          console.log("Receipt: ", receipt);
          this.$toasted.show("Transaction completed successfully");
        })
        .on("error", (error) => {
          console.log("Error receipt: ", error);
        });
    },
    harvest() {
      this.getInstance.methods
        .withdraw()
        .send({
          from: this.getUserAccount,
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction hash", hash);
          this.$toasted.show("Transaction submitted to the network");
        })
        .on("receipt", (receipt) => {
          console.log("Receipt: ", receipt);
          this.$toasted.show("Transaction completed successfully");
        })
        .on("error", (error) => {
          console.log("Error receipt: ", error);
        });
    },
  },
  computed: {
    ...mapGetters("wallet", [
      "getRate",
      "getWeb3",
      "getInstance",
      "getReferral",
      "getUserAccount",
      "getContractABI",
      "getContractAddress",
    ]),
  },
  watch: {
    getInstance() {
      this.readValue();
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1170px;
}
.error-message {
  position: relative;
  left: 5px;
  top: 5px;
  font-size: 13px;
  color: red;
}
</style>