const state = () => ({
  rate: 0,
  web3: null,
  userAccount: null,
  instance: null,
  referral: '0x3D71E5CaF173Dbea7A199ca8bDA99c327Aa48a0e',
  contractAddress: "0x9275FbCD0cFAA8Ba2b53dAB81f04d8B0Ba1eE339",
  contractABI: [
    {
      constant: true,
      inputs: [],
      name: "PERCENTS_DIVIDER",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserDownlineCount",
      outputs: [{ name: "referrals", type: "uint256[5]" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserDividends",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserAvailable",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "started",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "TIME_STEP",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserReferrer",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "withdraw",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserReferralTotalBonus",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "PROJECT_FEE",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "getSiteInfo",
      outputs: [
        { name: "_totalInvested", type: "uint256" },
        { name: "_totalBonus", type: "uint256" },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalInvested",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "PERCENT_STEP",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "referrer", type: "address" },
        { name: "plan", type: "uint8" },
      ],
      name: "invest",
      outputs: [],
      payable: true,
      stateMutability: "payable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "", type: "uint256" }],
      name: "REFERRAL_PERCENTS",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserInfo",
      outputs: [
        { name: "totalDeposit", type: "uint256" },
        { name: "totalWithdrawn", type: "uint256" },
        { name: "totalReferrals", type: "uint256" },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalRefBonus",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserReferralWithdrawn",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "getContractBalance",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserTotalDeposits",
      outputs: [{ name: "amount", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "commissionWallet",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserAmountOfDeposits",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "plan", type: "uint8" }],
      name: "getPlanInfo",
      outputs: [
        { name: "time", type: "uint256" },
        { name: "percent", type: "uint256" },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { name: "userAddress", type: "address" },
        { name: "index", type: "uint256" },
      ],
      name: "getUserDepositInfo",
      outputs: [
        { name: "plan", type: "uint8" },
        { name: "percent", type: "uint256" },
        { name: "amount", type: "uint256" },
        { name: "start", type: "uint256" },
        { name: "finish", type: "uint256" },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserCheckpoint",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "INVEST_MIN_AMOUNT",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserReferralBonus",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserTotalWithdrawn",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "userAddress", type: "address" }],
      name: "getUserTotalReferrals",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ name: "wallet", type: "address" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "user", type: "address" }],
      name: "Newbie",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "user", type: "address" },
        { indexed: false, name: "plan", type: "uint8" },
        { indexed: false, name: "amount", type: "uint256" },
      ],
      name: "NewDeposit",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "user", type: "address" },
        { indexed: false, name: "amount", type: "uint256" },
      ],
      name: "Withdrawn",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "referrer", type: "address" },
        { indexed: true, name: "referral", type: "address" },
        { indexed: true, name: "level", type: "uint256" },
        { indexed: false, name: "amount", type: "uint256" },
      ],
      name: "RefBonus",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "user", type: "address" },
        { indexed: false, name: "totalAmount", type: "uint256" },
      ],
      name: "FeePayed",
      type: "event",
    },
  ],
});
export default state;
