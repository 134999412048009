<template>
  <div class="loading loaded">
    <div>
      <section id="home" class="top-section">
        <div class="container">
          <div class="text-center pb-3">
            <p class="fw-bold fs-2">BNBMill</p>
            <p class="">
              #1 Community Experimental yield farm <br />
              on Binance Smart Chain
            </p>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 px-md-3 ps-md-2">
              <div class="card border-0 mb-3 ms-md-1">
                <div class="card-body">
                  <p class="card-subtitle">Total Value Deposited</p>
                  <p class="card-title mb-0">{{ totalInvested }} BNB</p>
                  <p class="card-text">
                    $ {{ (Number(getRate) * Number(totalInvested)).toFixed(4) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 px-md-3 pe-md-2">
              <div class="card border-0 mb-3 me-md-1">
                <div class="card-body">
                  <p class="card-subtitle">Total Referral Earnings</p>
                  <p class="card-title mb-0">{{ totalReferralEarnings }} BNB</p>
                  <p class="card-text">
                    $ {{ (Number(getRate) * Number(totalReferralEarnings)).toFixed(4) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-8 col-sm-10 px-3">
              <div class="mb-3 btn-box">
                <router-link to="dashboard" class="btn btn-primary w-100 mt-3">
                  Dashboard
                </router-link>
                <a
                  href="https://docs.bnbmill.com"
                  target="_blank"
                  class="btn btn-primary w-100 mt-3"
                  >Information
                </a>
                <a
                  href="https://bscscan.com/address/0x9275FbCD0cFAA8Ba2b53dAB81f04d8B0Ba1eE339#code"
                  target="_blank"
                  class="btn btn-primary w-100 mt-3"
                  >Verified Contract
                </a>
                <a
                  href="https://t.me/bnbmill"
                  target="_blank"
                  class="btn btn-primary w-100 mt-3"
                  >Telegram
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="community bg-white">
        <div class="container">
          <div class="community-block">
            <p class="mb-3 text-center fs-4">
              Start your yield farm journey <br />
              with BNBMill community
            </p>
            <div class="row">
              <div class="col-md-7 col-lg-4 mx-auto text-center mt-3">
                <img src="../assets/secure.png" class="community-img" />
                <p class="fs-5 fw-light">Ironclad security</p>
                <p class="fw-light mb-0 mt-n1 lh-xs">
                  <small
                    >The smart contract code has been successful audited by our in-house
                    developers
                    <!--<a href="#" class="text-dark">HazeCrypto</a>
                    and
                    <a href="#" target="_blank" class="text-dark">CertiK</a>-->
                    which guarantees the protection of your assets from all
                    external risks.</small
                  >
                </p>
              </div>
              <div class="col-md-7 col-lg-4 mx-auto text-center mt-3">
                <img src="../assets/apr.png" class="community-img" />
                <p class="fs-5 fw-light">High &amp; Stable APR</p>
                <p class="fw-light mb-0 mt-n1 lh-xs">
                  <small
                    >In the code sets the highest APR among all yield farms on
                    BSC, rules of a smart contract can’t be changed, nothing can
                    affect the amount of income.</small
                  >
                </p>
              </div>
              <div class="col-md-7 col-lg-4 mx-auto text-center mt-3">
                <img src="../assets/support.png" class="community-img" />
                <p class="fs-5 fw-light">Customer support</p>
                <p class="fw-light mb-0 mt-n1 lh-xs">
                  <small
                    >BNBMill 24/7 provides you our knowledgable and experienced
                    customer support team in
                    <a
                      href="https://t.me/bnbmill"
                      target="_blank"
                      class="text-dark"
                      >Telegram.</a
                    ></small
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      timer: null,
      totalReferralEarnings: "0.00",
      totalInvested: "0.00",
    };
  },
  mounted() {
    this.readValue();
    this.timer = setInterval(() => {
      this.readValue();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    readValue() {
      const web3 = new Web3("https://bsc-dataseed1.binance.org:443");
      let instance = new web3.eth.Contract(
        this.getContractABI,
        this.getContractAddress
      );
      Promise.all([instance.methods.getSiteInfo().call()]).then(
        ([getSiteInfo]) => {
          this.totalInvested = Number(
            web3.utils.fromWei(getSiteInfo._totalInvested.toString(), "ether")
          ).toFixed(4);
          this.totalReferralEarnings = (
            Number(this.totalInvested * 13) / 100
          ).toFixed(4);
        }
      );
    },
  },
  computed: {
    ...mapGetters("wallet", [
      "getRate",
      "getWeb3",
      "getInstance",
      "getReferral",
      "getUserAccount",
      "getContractABI",
      "getContractAddress",
    ]),
  },
};
</script>
